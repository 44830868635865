.App {
  text-align: center;
}

.App-header {
  background-color: #1c1c1c;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

* {
  color: #ffffff
}

a {
  color: #30a6a6;
  text-decoration: none;
}

.App-center {
  background-color: #1c1c1c;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  justify-content: center;
}

.App-footer {
  background-color: #1c1c1c;
  align-content:center;
  min-height: 10vh;
  color:lightgray;
}

.App-element {
  display: flow;
  width: 100vmin;
  background-color: #212121;
  flex: 1;
  margin: 4vmin;
  padding-left: 1vmin;
  padding-right: 1vmin;
  border-radius: 3vmin;
  box-shadow: .5vmin .5vmin .5vmin rgba(0, 0, 0, 0.25);
}

h1 {
  font-family: Consolas;
}

h2 {
  font-family: Consolas;
}


