.MainMenu {
    background-color: #212121;
}

.MenuItems {
    align-items: center;
}

.Logo {
    height: 7vmin;
    pointer-events: none;
}