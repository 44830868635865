.SplitScreen {
    display: flex;
    height: 60vmin;
    width: 190vmin;
}

#discord-col {
    background-color: #212121;
    flex: 1;
    margin:1vmin;
    border-radius: 3vmin;
    box-shadow: .5vmin .5vmin .5vmin rgba(0, 0, 0, 0.25);
}

#minecraft-col {
    background-color: #212121;
    flex: 1;
    margin: 1vmin;
    border-radius: 3vmin;
    box-shadow: .5vmin .5vmin .5vmin rgba(0, 0, 0, 0.25);
}

.CommunityLogo{
    height: 15vmin;
    align-items: center;
    pointer-events: none;
}